import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { isEmpty, omitBy } from "lodash";
import { incrementMetric } from "@src/utils/logClient";

const Class = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { classID, postID, target, conversationID, pcrID, hash } = parsedLocation;
  const commonParams = new URLSearchParams(omitBy({ hash: hash?.toString() }, isEmpty));
  useRedirectHandler(() => {
    const getURL = () => {
      if (classID && target === "story" && postID) {
        return `https://teach.classdojo.com/#/classes/${classID}/story/${postID}?`;
      }
      if (classID && target === "story") {
        return `https://teach.classdojo.com/#/classes/${classID}/story?`;
      }
      if (classID && target === "portfolio") {
        return `https://teach.classdojo.com/#/classes/${classID}/portfolio?`;
      }
      if (classID && target === "addStudentsToDirectory") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?modal=addStudentsToDirectory&`;
      }
      if (classID && target === "studentLogins") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?openModal=EditClassStudentLoginsModal&`;
      }
      if (classID && pcrID && target === "parentConnectionRequests") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?parentConnectionRequests=true&pcrId=${pcrID}&`;
      }
      if (classID && (target === "pendingParents" || target === "connections")) {
        return `https://teach.classdojo.com/#/classes/${classID}/points?editClass=parents&`;
      }
      if (classID && target === "messages" && conversationID) {
        return `https://teach.classdojo.com/#/classes/${classID}/messaging/${conversationID}?`;
      }
      if (classID && target === "dojoIslandsTab") {
        return `https://teach.classdojo.com/#/classes/${classID}/dojoIslands?`;
      }
      if (target === "dojoIslandsTab") {
        return `https://teach.classdojo.com/#/launchpad?dojoIslandsTab=true&`;
      }
      if (target === "messages" && conversationID) {
        return `https://teach.classdojo.com/#/messaging/${conversationID}?`;
      }
      if (classID && target === "messages") {
        return `https://teach.classdojo.com/#/classes/${classID}/messaging/?`;
      }
      if (classID && target === "classSettings") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?settings=true&`;
      }
      if (classID && target === "classTeachers") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?connectTeachers=true&`;
      }
      if (classID && target === "classStudents") {
        return `https://teach.classdojo.com/#/classes/${classID}/points?connectStudents=true&`;
      }
      if (classID && target === "createEvent") {
        return `https://teach.classdojo.com/#/classes/${classID}/events/create?`;
      }
      if (classID) {
        return `https://teach.classdojo.com/#/classes/${classID}/points?`;
      }

      incrementMetric("external_site.universal_link.fallback", { component: "Class" });
      return `https://teach.classdojo.com/#/?`;
    };
    const url = getURL();

    return url + commonParams.toString();
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Class;
